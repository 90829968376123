import React, { useEffect } from "react";
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";

import AppLocale from "../lngProvider";
import servidor from "util/Server"
import Axios from 'axios'
import Storage from 'util/store/Storage'
import moment from 'moment'

import { getDataDominio } from "./AppServices"
import { SignIn } from "./signIn/index";
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";
import PageNotFound from "app/routes/extraPages/routes/404"
import PreLoaderTerpel from "components/PreLoaderTerpel/PreLoaderTerpel";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const App = (props, _context) => {
  const dispatch = useDispatch();
  const { darkTheme, locale, isDirectionRTL } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  useEffect(() => {
    window._MUI_USE_NEXT_TYPOGRAPHY_VARIANTS_ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);



  let applyTheme = createTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme)
  }
  if (location.pathname === '/') {
    if (authUser === null) {
      return (<Redirect to={'/app/dashboard'} />);
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return (<Redirect to={'/app/dashboard'} />);
    } else {
      return (<Redirect to={initURL} />);
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }
  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>

                <Route path={`${match.url}app`} component={props.isAutenticado ? AppLayout : SignIn} />
                <Route path={`${match.url}app/404`}
                  component={PageNotFound} />
                <Route
                  component={props.isAutenticado ? AppLayout : SignIn} />

              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

class APPClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAutenticado: false,
      usuario: [],
      loader: true
    }
    Storage.subscribe(() => {
      this.setState({
        isAutenticado: Storage.getState().token,
        usuario: Storage.getState().usuario,
      })
    })
  }

  componentWillMount() {
    let dCompleto = window.location.href
    dCompleto = dCompleto.replace("https://", "").replace("http://", "").replace(":3000", "").replace(":3001", "").replace("//", "/")
    let dsplit = dCompleto.split("/")
    if (dsplit[1] === "app") {
      dCompleto = dsplit[0]
    } else {
      dCompleto = dsplit[0] + "/" + dsplit[1]
    }
    if (dCompleto.slice(-1) === "/") {
      dCompleto = dCompleto.replace("/", "")
    }
    this.ontenerDominio(dCompleto)
  }

  ontenerDominio = async (dCompleto) => {
    const negocioDomain = await getDataDominio(dCompleto)
    try {
      document.title = negocioDomain.data.alias
      Storage.dispatch({
        type: "OBTENCION_DOMINIO",
        dominio: negocioDomain.id,
        negocio: negocioDomain.negocio_id,
        infoDominio: negocioDomain.data,
        loaderDominio: false,
        tipo_empresa: negocioDomain.tipo
      })
      Storage.dispatch({
        type: "ACTUALIZACION_DATOS",
        actualizarvista: true,
        loaderDominio: false
      })
      this.obtenerParametros(negocioDomain.data)
      this.validarSession(negocioDomain.negocio_id)
    } catch (error) {
      document.title = "recurso no encontrado"
      Storage.dispatch({
        type: "OBTENCION_DOMINIO",
        dominio: 0,
        negocio: 0,
        infoDominio: {},
        loaderDominio: false,
        tipo_empresa: 0
      })
      Storage.dispatch({
        type: "ACTUALIZACION_DATOS",
        actualizarvista: true,
        loaderDominio: false
      })
      this.validarSession(0)

    }

  }

  obtenerParametros = (params) => {
    if (params) {
      if (params.parametros) {
        Storage.dispatch({
          type: "OBTENCION_COLORES_DOMINIO",
          color1: params.dominio.parametros.color1,
          color2: params.dominio.parametros.color2
        })
      }

    }
  }

  validarSession = (domi) => {
    if (sessionStorage.getItem('token') || domi === 0) {
      let ntoken = 'Bearer '.concat(sessionStorage.getItem('token'))
      let url = servidor.host + servidor.puertoApp;
      Axios.get(url + "/api/v1/", { headers: { Authorization: ntoken, identificadorNegocio: domi } })
        .then(response => {
          sleep(3000).then(() => {
            this.setState({ loader: false })
            if (response.status === 205) {
              Storage.dispatch({
                type: "VALIDAR_SESION",
                token: false,
                tiempo: ''
              })
              sessionStorage.removeItem("token");
            } else if (response.status === 206) {
              Storage.dispatch({
                type: "VALIDAR_SESION",
                token: false,
                tiempo: ''
              })
            } else if (response.status === 200) {
              Storage.dispatch({
                type: "VALIDAR_SESION",
                token: true,
                tiempo: moment(response.data.data.fechavencimiento).format('hh:mm:ss a')
              })
            }
          })


        })
        .catch(() => {
          Storage.dispatch({
            type: "VALIDAR_SESION",
            token: false,
            tiempo: ''
          })
          this.setState({ loader: false })
        })
    } else {
      Storage.dispatch({
        type: "VALIDAR_SESION",
        token: false
      })
      this.setState({ loader: false })
    }
  }





  render() {
    const { location, history, match } = this.props
    const { isAutenticado, loader } = this.state
    return (
      loader
        ?
        <PreLoaderTerpel />
        :
        <App
          location={location}
          history={history}
          match={match}
          isAutenticado={isAutenticado}
        />
    );
  }
}


export default APPClass;
