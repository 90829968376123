import React, { useEffect, useState } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import { toggleCollapsedNav } from 'actions/Setting';
import Servidor from 'util/Server';
import Storage from 'util/store/Storage';
import { formatearFecha } from 'util/formatDate';
const Index = (props) => {
  const { negocio } = Storage.getState();
  const dispatch = useDispatch();
  const { drawerType } = useSelector(({ settings }) => settings);
  const [searchBox, setSearchBox] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [FECHA, setFECHA] = useState()

  const sampleLocation = useLocation()

  const onSearchBoxSelect = () => {
    setSearchBox(!searchBox);
  };

  useEffect(()=>{
    const tiempoTranscurrido = Date.now()
    const hoy = new Date(tiempoTranscurrido)
    setFECHA(formatearFecha(hoy.toUTCString(), 'hh:mm:ss DD/MM/YYYY'))
  },[])


  setInterval(()=>{
    const tiempoTranscurrido = Date.now()
    const hoy = new Date(tiempoTranscurrido)
    setFECHA(formatearFecha(hoy, 'hh:mm:ss DD/MM/YYYY'))
  },20000)

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const updateSearchText = (evt) => {
    setSearchText(evt.target.value);
  };

  const drawerStyle = () => {
    if (drawerType.includes(FIXED_DRAWER)) return 'd-block d-xl-none'
    return drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';
  }
  
  
  let color1 = Storage.getState().color1;
  console.log('==>',color1)
  return (
    <AppBar  style={{ backgroundColor: color1, zIndex: 900 }}>
      <Toolbar className='app-toolbar' disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle()}`}
          aria-label='Menu'
          onClick={onToggleCollapsedNav}>
          <span className='menu-icon' />
        </IconButton>

        <Link className='app-logo mr-2 d-none d-sm-block' to='/'>
          <img
            src={Servidor.hostMultimedia + negocio + '/pagina/logo_login_177_66.png'}
            alt='Jambo'
            title='Jambo'
          />
        </Link>

        <ul className='header-notifications list-inline ml-auto'>
          <li className='d-inline-block d-lg-none list-inline-item'>
            <Dropdown
              className='quick-menu nav-searchbox'
              isOpen={searchBox}
              toggle={onSearchBoxSelect}>
              <DropdownToggle
                className='d-inline-block'
                tag='span'
                data-toggle='dropdown'>
                <IconButton className='icon-btn'>
                  <i className='zmdi zmdi-search zmdi-hc-fw' />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className='p-0'>
                <SearchBox
                  styleName='search-dropdown'
                  placeholder=''
                  onChange={updateSearchText}
                  value={searchText}
                />
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className='list-inline-item'>
          </li>
          <li className='list-inline-item app-tour'>
          {sampleLocation.pathname.includes( '/app/dashboard') ? <span style={{fontFamily:'arial', fontWeight:'bold', fontSize:'20px'}}>Ultima actualizacion: {FECHA}</span> : ''}
          </li>
        </ul>

        <div className='ellipse-shape' />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
